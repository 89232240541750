import { Injectable } from '@angular/core';
import { ApiUrl } from 'src/app/Services/api-url';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { LoginService } from '../login/login-service';


@Injectable()
export class TimeSheetService {
    constructor(private http: Http,
        private router: Router,
        private loginService: LoginService) { }

    // timeSheetSession(submit) {
    //     // AutoRefreshComponent.reset.next(void 0);
    //     const token = localStorage.getItem('id_token');
    //     const headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     headers.append('Authorization', token);

    //     return this.http.post(ApiUrl.BaseUrl + ApiUrl.TimeSheetInOrOut, submit,
    //         { headers: headers })
    //         .map(res => {
    //             const responseToken = res.headers.get('refresh-token');
    //             localStorage.setItem('id_token', responseToken);
    //             return res.json();
    //         }).catch(err => {
    //             if (err.status === 401) {
    //                 this.loginService.logout();
    //             }
    //             return '{}';
    //         });
    // }

    getWeekSheet(submit) {
        // AutoRefreshComponent.reset.next(void 0);
        const token = localStorage.getItem('id_token');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', token);

        return this.http.post(ApiUrl.BaseUrl + ApiUrl.GetWeekSheet, submit,
            { headers: headers })
            .map(res => {
                const responseToken = res.headers.get('refresh-token');
                localStorage.setItem('id_token', responseToken);
                return res.json();
            }).catch(err => {
                if (err.status === 401) {
                    this.loginService.logout();
                }
                return '{}';
            });
    }

    getAllWeekSheet(submit) {
        // AutoRefreshComponent.reset.next(void 0);
        const token = localStorage.getItem('id_token');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', token);

        return this.http.post(ApiUrl.BaseUrl + ApiUrl.GetAllWeekSheet, submit,
            { headers: headers })
            .map(res => {
                const responseToken = res.headers.get('refresh-token');
                localStorage.setItem('id_token', responseToken);
                return res.json();
            }).catch(err => {
                if (err.status === 401) {
                    this.loginService.logout();
                }
                return '{}';
            });
    }

    getReport(submit) {
        // AutoRefreshComponent.reset.next(void 0);
        const token = localStorage.getItem('id_token');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', token);

        return this.http.post(ApiUrl.BaseUrl + ApiUrl.GetReport, submit,
            { headers: headers })
            .map(res => {
                const responseToken = res.headers.get('refresh-token');
                localStorage.setItem('id_token', responseToken);
                return res.json();
            }).catch(err => {
                if (err.status === 401) {
                    this.loginService.logout();
                }
                return '{}';
            });
    }


    leaveRequest(submit) {
        // AutoRefreshComponent.reset.next(void 0);
        const token = localStorage.getItem('id_token');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', token);

        return this.http.post(ApiUrl.BaseUrl + ApiUrl.LeaveRequest, submit,
            { headers: headers })
            .map(res => {
                const responseToken = res.headers.get('refresh-token');
                localStorage.setItem('id_token', responseToken);
                return res.json();
            }).catch(err => {
                if (err.status === 401) {
                    this.loginService.logout();
                }
                return '{}';
            });
    }

    createHoliday(submit) {
        // AutoRefreshComponent.reset.next(void 0);
        const token = localStorage.getItem('id_token');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', token);

        return this.http.post(ApiUrl.BaseUrl + ApiUrl.CreateHoliday, submit,
            { headers: headers })
            .map(res => {
                const responseToken = res.headers.get('refresh-token');
                localStorage.setItem('id_token', responseToken);
                return res.json();
            }).catch(err => {
                if (err.status === 401) {
                    this.loginService.logout();
                }
                return '{}';
            });
    }

    leaveHistory(submit) {
      // AutoRefreshComponent.reset.next(void 0);
      const token = localStorage.getItem('id_token');
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', token);

      return this.http.post(ApiUrl.BaseUrl + ApiUrl.LeaveHistory, submit,
          { headers: headers })
          .map(res => {
              const responseToken = res.headers.get('refresh-token');
              localStorage.setItem('id_token', responseToken);
              return res.json();
          }).catch(err => {
              if (err.status === 401) {
                  this.loginService.logout();
              }
              return '{}';
          });
  }

    getHolidays(submit) {
        // AutoRefreshComponent.reset.next(void 0);
        const token = localStorage.getItem('id_token');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', token);

        return this.http.post(ApiUrl.BaseUrl + ApiUrl.GetHolidays, submit,
            { headers: headers })
            .map(res => {
                const responseToken = res.headers.get('refresh-token');
                localStorage.setItem('id_token', responseToken);
                return res.json();
            }).catch(err => {
                if (err.status === 401) {
                    this.loginService.logout();
                }
                return '{}';
            });
    }

    upComingHolidays(submit) {
      // AutoRefreshComponent.reset.next(void 0);
      const token = localStorage.getItem('id_token');
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', token);

      return this.http.post(ApiUrl.BaseUrl + ApiUrl.UpcomingHolidays, submit,
          { headers: headers })
          .map(res => {
              const responseToken = res.headers.get('refresh-token');
              localStorage.setItem('id_token', responseToken);
              return res.json();
          }).catch(err => {
              if (err.status === 401) {
                  this.loginService.logout();
              }
              return '{}';
          });
  }

    sendTimeSheet(submit) {
        // AutoRefreshComponent.reset.next(void 0);
        const token = localStorage.getItem('id_token');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', token);

        return this.http.post(ApiUrl.BaseUrl + ApiUrl.SendTimeSheetToMail, submit,
            { headers: headers })
            .map(res => {
                const responseToken = res.headers.get('refresh-token');
                localStorage.setItem('id_token', responseToken);
                return res.json();
            }).catch(err => {
                if (err.status === 401) {
                    this.loginService.logout();
                }
                return '{}';
            });
    }

    cancelLeaveRequest(submit) {
        // AutoRefreshComponent.reset.next(void 0);
        const token = localStorage.getItem('id_token');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', token);

        return this.http.post(ApiUrl.BaseUrl + ApiUrl.CancelLeaveRequest, submit,
            { headers: headers })
            .map(res => {
                const responseToken = res.headers.get('refresh-token');
                localStorage.setItem('id_token', responseToken);
                return res.json();
            }).catch(err => {
                if (err.status === 401) {
                    this.loginService.logout();
                }
                return '{}';
            });
    }

    getLeaveRequest(submit) {
        // AutoRefreshComponent.reset.next(void 0);
        const token = localStorage.getItem('id_token');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', token);

        return this.http.post(ApiUrl.BaseUrl + ApiUrl.UpcomingLeaveRequest, submit,
            { headers: headers })
            .map(res => {
                const responseToken = res.headers.get('refresh-token');
                localStorage.setItem('id_token', responseToken);
                return res.json();
            }).catch(err => {
                if (err.status === 401) {
                    this.loginService.logout();
                }
                return '{}';
            });
    }

    // getTimeSheetReport(submit) {
    //     // AutoRefreshComponent.reset.next(void 0);
    //     const token = localStorage.getItem('id_token');
    //     const headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     headers.append('Authorization', token);

    //     return this.http.post(ApiUrl.BaseUrl + ApiUrl.TimeSheetReport, submit,
    //         { headers: headers })
    //         .map(res => {
    //             const responseToken = res.headers.get('refresh-token');
    //             localStorage.setItem('id_token', responseToken);
    //             return res.json();
    //         }).catch(err => {
    //             if (err.status === 401) {
    //                 this.loginService.logout();
    //             }
    //             return '{}';
    //         });
    // }
}
